@import '../../../../styles/index.scss';

.icon {
  height: rem-calc(85);

  svg {
    height: 100%;
    width: auto;
    max-width: 100%;
  }
}

.fileTableIcon {
  path {
    fill: $gray-80;
  }
}
