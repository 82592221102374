@import '/src/styles/index.scss';

// Reduce height of navigation
.navigationMenu > div:first-child {
  padding-block: rem-calc(4);
}

// Move non-mobile pop-up menu up
.navigationMenu > div:last-child {
  transform: translateY(-13px);
}
