%activeIndicator {
  position: absolute;
  background: $blue-primary;
  border: 3px solid $white;
  border-radius: 50%;
  right: rem-calc(-8);
  top: rem-calc(-8);
  width: rem-calc(22);
  height: rem-calc(22);
  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $white;
    }
  }
}
