$font-family: 'Manrope', sans-serif;

$body-font-family: $font-family;
$helvetica-font-family: $font-family;
$font-weight-normal: normal;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$body-font-color: $gray-100;
$anchor-font-color: $blue-primary;

$header-font-family: $body-font-family;
$header-font-weight: $font-weight-normal;
$header-font-style: $font-weight-normal;
$header-font-color: $gray-100;
$header-line-height: 1.4;
$header-top-margin: 0;
$header-bottom-margin: 0.5rem;
$header-text-rendering: optimizeLegibility;

$h1-font-size: rem-calc(56);
$h2-font-size: rem-calc(48);
$h3-font-size: rem-calc(40);
$h4-font-size: rem-calc(32);
$h5-font-size: rem-calc(24);
$h6-font-size: rem-calc(20);

$big-font-size: rem-calc(18);
$font-size: rem-calc(16);
$small-font-size: rem-calc(14);
$xsmall-font-size: rem-calc(12);
$xxsmall-font-size: rem-calc(11);

$h1-line-height: rem-calc(70);
$h2-line-height: rem-calc(60);
$h3-line-height: rem-calc(50);
$h4-line-height: rem-calc(40);
$h5-line-height: rem-calc(30);
$h6-line-height: rem-calc(25);

$big-line-height: rem-calc(25);
$line-height: rem-calc(20);
$small-line-height: rem-calc(20);
$xsmall-line-height: rem-calc(16);

$small-font-color: scale-color($header-font-color, $lightness: 35%);

$paragraph-font-weight: $font-weight-normal;
$paragraph-font-size: $font-size;
$paragraph-line-height: $line-height;
$paragraph-margin-bottom: rem-calc(20);
$paragraph-aside-font-size: rem-calc(14);
$paragraph-aside-line-height: 1.35;
$paragraph-aside-font-style: italic;
$paragraph-text-rendering: optimizeLegibility;
