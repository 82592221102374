%modalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($gray-100, 75%);
  z-index: $drawerIndex;
}

%modal {
  width: 80%;
  max-width: rem-calc(600);
  max-height: rem-calc(774);

  overflow: auto;
  background-color: _var(--white);
  border-radius: _var(--rounded-2xl);
}
