@import 'src/styles/index.scss';
@import '../settings.module.scss';

.container {
  user-select: none;
  background: _var(--white);
  padding: _var(--padding-2) _var(--padding-3);
  display: flex;
  align-items: center;
  gap: _var(--padding-6);
  z-index: $subNavIndex - 2; // Place below nav sub-menus

  &--isSmall {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    border-top: 1px solid _var(--gray-200);
    height: $mobileToolbarHeight;
  }

  &--compact {
    padding-block: _var(--padding-1);
  }

  &:not(&--isSmall) {
    position: sticky;
    margin-top: _var(--padding-8);
    bottom: 0;
    left: _var(--padding-12);
    right: _var(--padding-12);
    border-radius: _var(--rounded-xl);
    border: 1px solid _var(--gray-300);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.18);
  }
}

.menuItemIcon {
  width: _var(--padding-6);
  height: _var(--padding-6);

  svg {
    width: 100%;
    height: 100%;
  }
}

.menuItemTitle {
  line-height: rem-calc(16);
}

.previewResolutionMenus {
  & > * {
    border-bottom: 1px solid _var(--gray-200);
  }
}

.revealPillContainer:not(:last-child) {
  margin: 0;
}

.primaryButton {
  min-width: rem-calc(96);
}

.aiGenerateButton {
  padding: _var(--padding-1) _var(--padding-2_5);

  .menuItemIcon {
    border: none;
  }

  &--active {
    background: _var(--gray-100);
    border-radius: _var(--rounded-md);

    .menuItemIcon,
    &:hover .menuItemIcon {
      box-shadow: none;
    }
  }
}
