@import '../../../../styles/index.scss';

.main {
  // Prevent double scollbars
  overflow: hidden;
}

.loader {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

//
// Typography
//

.heading {
  margin-bottom: rem-calc(18);
  padding-right: rem-calc(28);
  font-size: $h5-font-size;
  line-height: $h5-line-height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  color: $gray-50;
  font-size: $small-font-size;
  line-height: $small-line-height;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-query(tablet) {
    white-space: normal;
  }
}

.loading {
  display: block;
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  color: $gray-50;
  @extend %aniFadeInOut;
}
