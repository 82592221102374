@import '/src/styles/index.scss';
@import '../settings.scss';

$mediumControlWidth: 260px;
$largeControlWidth: 340px;

.container {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  container-type: inline-size;
  container-name: preview-resolution-menus;

  &__primaryMenu {
    width: rem-calc(62);

    .select__button__text {
      display: none;
    }
  }

  &__subMenu {
    display: none;
    position: absolute;
    top: 0;
    min-width: rem-calc(62);
    transform: translateY(-100%);
  }

  // &:focus-within &__subMenu {
  //   display: block;
  // }

  // Show secondary menu next to primary
  @container preview-resolution-menus (min-width: #{$mediumControlWidth}) {
    &__primaryMenu {
      width: rem-calc(68);

      &:not(:last-child) {
        .select__button {
          border-right-width: 0;
        }
      }
    }

    &__subMenu {
      display: flex;
      height: 100%;
      position: static;
      transform: none;
    }
  }

  // Show full sized group control
  @container preview-resolution-menus (min-width: #{$largeControlWidth}) {
    &__primaryMenu {
      width: unset;
      max-width: rem-calc(160);

      .select__button__text {
        display: inline;
      }
    }

    &__subMenu {
      max-width: rem-calc(210);
    }
  }
}

.container--focused {
  .container__subMenu {
    display: block;
  }
}

.select {
  position: relative; // Contain absolute menu child

  &__button {
    @extend %universalSelectControl;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: rem-calc(8) rem-calc(10);
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $white;
    color: $gray-70;
    font-size: $small-font-size;
    line-height: rem-calc(16.8);
    border: 1px solid $gray-20;
    border-bottom: 0;

    &__text {
      display: block;
      max-width: calc(100% - 16px);
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: rem-calc(26);
    }

    // Button icon
    &__icon {
      max-width: rem-calc(24);
      height: rem-calc(24);
      font-size: 0;

      &--gray70 {
        path {
          fill: $gray-70;
        }
      }
    }

    // Add space between icon & text
    &__icon + &__text {
      text-indent: rem-calc(8);
    }

    // Button chevron
    svg:last-of-type {
      margin-top: 1px;
      margin-left: rem-calc(8);
      width: rem-calc(8);
      min-width: rem-calc(8);
      flex: 0 1 auto;

      path {
        fill: $gray-60;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    cursor: pointer;
  }

  // Apply new theme for expanded layout
  @container preview-container (min-width: #{$expandedLayoutWidth}) {
    &__button {
      padding: rem-calc(10) rem-calc(12) rem-calc(10) rem-calc(10);
      background-color: $gray-10;
      border-color: $gray-30;
      border-bottom: 1px solid $gray-30;
    }
  }
}

.editLayoutField {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;

  &__input {
    display: block;
    width: calc(50% - 12px);
    min-width: rem-calc(65);
    padding-left: _var(--global-xsm-padding);
    color: $gray-70;
    background-color: $white;
    border-radius: 0;
    border-top: 1px solid $gray-20;
    border-left: 1px solid $gray-20;
    border-right: 1px solid $gray-20;
  }

  &__separator {
    font-family: helvetica, arial, sans-serif;
    display: block;
    width: rem-calc(24);
    line-height: rem-calc(41);
    text-align: center;
    color: $gray-60;
    background-color: $gray-10;
    border-top: 1px solid $gray-20;
  }

  @container preview-container (min-width: #{$expandedLayoutWidth}) {
    &__input {
      padding-left: _var(--global-padding);
      background-color: $gray-10;
      border-color: $gray-30;
      border-bottom: 1px solid $gray-30;
    }

    &__separator {
      border-top: 1px solid $gray-30;
      border-bottom: 1px solid $gray-30;
      background-color: $gray-20;
    }
  }
}
