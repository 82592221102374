@import '/src/styles/index.scss';

$color-picker-size: rem-calc(50);
$color-picker-size-small: rem-calc(40);

//
// Blocks
//

.container {
  display: flex;
  flex-wrap: nowrap;
  position: relative; // Place picker picker
  border-color: _var(--gray-300);
  border-radius: _var(--rounded);

  &--small {
    padding: _var(--padding-2) _var(--padding-5);
  }
}

//
// Elements
//

.colorPicker {
  position: absolute;
  z-index: $drawerIndex + 2;
  pointer-events: auto;

  &__card {
    padding: _var(--padding-2);
    background: _var(--body-background-color);
    border-radius: _var(--rounded);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.15) 0px 8px 16px;

    /*
    * The current color picker library doesn't let us change the styles of the presets.
    * Here we're trying to override this styles.
    */
    :global(#rbgcp-inputs-wrap) {
      & + div > div:last-child {
        justify-content: flex-start !important;
        gap: _var(--global-xsm-padding);
      }
    }
  }

  :global(#rbgcp-input),
  :global(#rbgcp-hex-input) {
    font-size: _var(--xsmall-font-size);
    height: rem-calc(24) !important;
    border-radius: 0 !important;
  }
}

.colorValue {
  position: relative;
  width: $color-picker-size;
  height: $color-picker-size;
  min-width: $color-picker-size;
  margin-right: _var(--padding-5);
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid _var(--gray-100);
  cursor: pointer;

  &--small {
    width: $color-picker-size-small;
    height: $color-picker-size-small;
    min-width: $color-picker-size-small;
  }

  &__input,
  &__color,
  &__color--background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  &__color,
  &__color--background {
  }

  &__color {
    z-index: 1;
  }

  &__color--background {
    z-index: 0;
  }

  &__input {
    opacity: 0; // Not visible
    display: block;
    z-index: 2; // top level
    cursor: pointer;
  }
}

.aside {
  flex-grow: 1; // Fill top level block
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.deleteButton {
  position: absolute;
  z-index: 1;
  top: rem-calc(-4);
  right: rem-calc(12);

  > svg {
    width: rem-calc(12);
  }
}

.editButton {
  color: _var(--primary-color);
  text-decoration: underline;
  font-size: _var(--small-font-size);
  line-height: _var(--small-line-height);
}

//
// Typography
//

.subHeading {
  font-size: _var(--small-font-size);
  line-height: _var(--small-line-height);
  color: _var(--gray-600);

  &--small {
    font-size: _var(--xsmall-font-size);
    line-height: _var(--xsmall-line-height);
  }
}
