%textLikeButton {
  background: none;
  border-width: 0;
  outline-width: 0;
  padding: 0;

  &:not(:disabled) {
    cursor: pointer;
  }
}
