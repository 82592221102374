@import '../../../styles/index.scss';

.container {
  overflow: auto;
  background-color: $white;
  border-top: 1px solid $gray-20;
  position: relative; // Contain modals

  &__header {
    padding: _var(--global-md-padding) _var(--global-md-padding) 0;
    border-bottom: 1px solid $gray-10;
  }

  &__main {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: rem-calc(500);
    padding: _var(--global-md-padding) _var(--global-md-padding) 0;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  &__footer {
    padding-left: 0 !important;
    padding-right: 0;
    color: $gray-40;
  }

  @container editor-container (min-width: #{$editorLayoutTwoColumnMain}) {
    display: flex;
    flex-direction: column;
    border-top-width: 0;

    &__header {
      padding: _var(--global-md-padding) _var(--global-lg-padding) 0;
    }

    &__main {
      margin: 0;
      max-width: 100%;
      flex: 1 1 auto; // Expand to fill vertical space
      padding: _var(--global-lg-padding);
    }

    &__footer {
      padding-bottom: 0 !important;
    }
  }
}

//
// Blocks
//

.fields {
  @extend %unstyledFieldset;
  flex: 1 1 auto;

  // Loading cursor over form
  &:disabled {
    cursor: wait;

    *,
    *:disabled {
      cursor: wait;
    }
  }

  // Fix overlay drop lag after sort
  &__overlayAccordionPanel {
    display: none;
  }
  &:active &__overlayAccordionPanel {
    display: block;
  }
}

.formGroup {
  margin-bottom: _var(--global-xlg-padding);

  &__footer {
    margin-top: _var(--global-lg-padding);
    padding-top: _var(--global-padding);
    border-top: 1px solid $gray-30;
  }
}

.formField {
  &--pill {
    position: relative; // for side button
    padding: _var(--global-md-padding);
    border: 1px solid $gray-20;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: _var(--global-sm-padding);
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;

    &__colorPicker {
      width: rem-calc(60);
      padding: rem-calc(12);
      cursor: pointer;
    }

    // add margin between color picker and input
    * + &__colorPicker {
      margin-left: _var(--global-sm-padding);
    }
  }

  // Add specificity
  // to overide button styles
  & &__sideButton {
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
    left: auto;
    line-height: _var(--global-md-padding);
    padding: _var(--global-sm-padding);

    svg {
      max-width: _var(--global-md-padding);
      max-height: _var(--global-md-padding);
    }
  }

  &:not(:last-child) {
    margin-bottom: _var(--global-lg-padding);
  }
}

.publishButton {
  margin-bottom: _var(--global-padding);
}

.deleteRowButton {
  @extend %textLikeButton;
  text-decoration: underline;
  font-size: $small-font-size;
  line-height: $small-line-height;
  transition: color 250ms;

  &:hover {
    color: $red-primary;
  }
}

.addRowButton {
  margin-top: _var(--global-lg-padding);
}

.addSectionButton {
  width: 100%;
}

.addAccordionRow {
  @extend %accordionHeading;
  width: 100%;
  margin-top: _var(--global-padding);
  background-color: $white;
  border: 1px solid $accordionHeaderBorderColor;

  &__text {
    @extend %accordionHeadingText;
    flex: 0 1 auto;
  }

  &__icon {
    @extend %accordionHeadingIcon;
  }
}

.sectionHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative; // position dropdown
  margin-bottom: _var(--global-padding);
  border-bottom: 1px solid $gray-20;
}

.colorInput {
  padding: $global-sm-padding;

  &:not(:disabled) {
    cursor: pointer;
  }
}

//
// Elements
//
.emptyDropArea {
  display: flex;
  justify-content: center;
  padding: _var(--global-xxlg-padding) _var(--global-padding);
  background-color: $gray-10;
  border: 1px dashed $gray-30;
  border-bottom-width: 0;

  > svg {
    max-width: rem-calc(28);
    max-height: rem-calc(28);
    > path {
      fill: $gray-70;
    }
  }
}

//
// Typography
//

.heading {
  margin: _var(--global-md-padding) 0 rem-calc(18);
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

.subHeading {
  margin: 0 0 _var(--global-padding);
  font-size: $small-font-size;
  line-height: $small-line-height;
  color: $gray-50;
}

.sectionName {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
  padding-bottom: _var(--global-xsm-padding);
}

.sectionHeading {
  margin-bottom: 0;
}

.groupHeading {
  margin-bottom: _var(--global-padding);
  padding-bottom: _var(--global-xsm-padding);
  font-size: $h6-font-size;
  line-height: $h6-line-height;
  border-bottom: 1px solid $gray-10;
}

.sectionDescription {
  color: $gray-60;
  margin-bottom: _var(--global-padding);

  > a {
    text-decoration: underline;
  }
}

.legend {
  padding-left: _var(--global-sm-padding);
  padding-right: _var(--global-sm-padding);
  margin-left: rem-calc(-12);

  &--small {
    font-size: $xsmall-font-size;
  }
}

.sectionGroupContainer {
  margin-bottom: rem-calc(24);
}

.sectionGroupLabel {
  margin-bottom: rem-calc(8);
}
