@import '../../../../styles/index.scss';

.list {
  list-style: none;
  display: inline-block;
}

.item {
  display: inline-block;

  .list--pill & {
    padding: rem-calc(3) rem-calc(8);
    margin-right: rem-calc(6);
    color: $gray-60;
    font-size: $small-font-size;
    line-height: $small-line-height;
    background-color: #f5f5f7;
  }
}
