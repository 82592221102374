%togglePanel {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  user-select: none; // remove highlighting
  font-family: $font-family;
}

%togglePanel__text {
}

%togglePanel__icon {
  align-self: center;
  width: rem-calc(22);
  height: rem-calc(22);
  padding: rem-calc(2) 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid $gray-30;
  transition: transform linear 240ms;

  > svg {
    max-width: rem-calc(9);

    > path {
      fill: $gray-50;
    }
  }
}

%togglePanel__icon--open {
  transform: rotate(180deg);
}
