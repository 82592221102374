@import '../../styles/index.scss';

.section {
  padding: _var(--global-xxlg-padding) 0;

  &__content {
    padding: 0 _var(--global-padding);
  }

  @include media-query(large-tablet) {
    padding: _var(--global-xxxxlg-padding) 0;
  }
}
