@import '../../../../styles/index.scss';
@import './settings.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  min-width: rem-calc(342);
  border-bottom: 1px solid $gray-20;
  background-color: $gray-10;
  box-shadow: inset 0 1px 0 darken($gray-20, 4%);

  // Place controls above z-index
  // of DOM elements in the widget embed
  position: relative;
  z-index: 1;

  &__left {
    display: flex;
    gap: rem-calc(12);
    flex-wrap: nowrap;
    flex: 1 1 auto;
    padding-right: _var(--global-padding);
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 auto;
    min-width: rem-calc(130);
    flex-wrap: nowrap;
  }

  &__right--hidden {
    display: none;
  }

  // Stick controls to bottom of the
  // container w/ room for scroll bars
  @container preview-container (min-width: #{$expandedLayoutWidth}) {
    position: absolute;
    bottom: _var(--global-padding);
    left: _var(--global-padding);
    right: _var(--global-xlg-padding);
    background-color: unset;
    border-bottom-width: 0;
    z-index: 1;
    box-shadow: none;

    &__left {
      gap: rem-calc(20);
    }

    &__right {
      padding-left: 0;

      .select:first-child {
        max-width: unset;
      }

      > *:last-child:not(:first-child) {
        width: auto;
        border-right: 1px solid $gray-30;
      }
    }
  }
}

.zoomControl {
  display: flex;
  width: $zoomControlWidthMobile;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid $gray-20;
  border-right: 1px solid $gray-20;
  border-left: 1px solid $gray-20;
  background-color: $white;

  &__button {
    padding: rem-calc(9);

    &:first-child {
      border-right: 1px solid $gray-20;
    }

    &:last-child {
      border-left: 1px solid $gray-20;
    }

    svg path {
      fill: $gray-40;
    }
  }

  &__indicator {
    display: block;
    width: rem-calc(56);
    text-align: center;
    font-size: $small-font-size;
    color: $gray-60;
  }

  @container preview-container (min-width: #{$expandedLayoutWidth}) {
    flex-basis: auto; // Remove constrained width
    background-color: $gray-10;
    border: 1px solid $gray-30;
    box-shadow: $expandedBoxShadow;

    &__button {
      padding: rem-calc(12);
      color: $gray-70;
      background-color: $gray-10;
      border-width: 0;

      &:first-child {
        border-right: 1px solid $gray-30;
      }

      &:last-child {
        border-left: 1px solid $gray-30;
      }
    }

    &__indicator {
      width: rem-calc(60);
    }
  }
}
