$input-font-family: _var(--input-font-family, _var(--body-font-family));
$input-font-size: _var(--input-font-size, _var(--body-font-size));
$input-font-weight: _var(--input-font-weight, _var(--body-font-weight));
$input-line-height: _var(--input-line-height, _var(--body-line-height));

%input {
  display: block;
  background: _var(--input-background-color, _var(--body-background-color));
  padding-top: _var(--input-padding-top, _var(--padding-3));
  padding-right: _var(--input-padding-right, _var(--padding-4));
  padding-bottom: _var(--input-padding-bottom, _var(--padding-3));
  padding-left: 0;
  text-indent: _var(--input-padding-left, _var(--padding-4));
  border-radius: _var(--input-border-radius, _var(--rounded-lg));
  border: _var(--input-border-width, 1px) solid
    _var(--input-border-color, _var(--border-color));
  outline: none;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  font-size: $input-font-size;
  line-height: $input-line-height;
  color: _var(--input-font-color, _var(--body-font-color));

  &:hover {
    border-color: _var(--input-border-color-hover, _var(--border-color-hover));
  }

  &:focus {
    border-color: _var(--border-color-focus);
    outline: _var(--input-outline-width, 1px) solid
      _var(--input-outline-color, _var(--border-color-focus));
    outline-offset: _var(--input-outline-offset, 0);
  }

  &:disabled {
    background: _var(
      --input-background-color,
      _var(--secondary-color-muted, _var(--body-background-color))
    );
    border-color: _var(--border-color-muted);
    cursor: not-allowed;
  }

  &::placeholder {
    color: _var(--input-placeholder-color, _var(--muted-font-color));
    font-family: _var(--input-placeholder-font-family, $input-font-family);
    font-weight: _var(--input-placeholder-font-weight, $input-font-weight);
    font-size: _var(--input-placeholder-font-size, $input-font-size);
    line-height: _var(--input-placeholder-line-height, $input-line-height);
  }
}

%textareaInput {
  padding-left: _var(--padding-5);
  text-indent: 0;
}

%inputSuccess {
  border-color: $green-primary;
}

%inputError {
  border-color: $red-primary;
}

%inputCaption {
  margin: _var(--padding-1) 0 0;
  color: _var(--caption-color, _var(--gray-600));
  font-weight: _var(--caption-font-weight, _var(--body-font-weight));
  font-size: _var(--caption-font-size, _var(--small-font-size));
  line-height: _var(--caption-line-height, _var(--small-line-height));
}

%inputCaptionError {
  color: $red-primary;
}

%inputCaptionSuccess {
  color: $green-primary;
}

%inputDropdownIcon {
  position: absolute;
  right: _var(--padding-4);
  top: 50%;
  transform: translateY(-50%);

  path {
    fill: _var(--input-font-color, _var(--body-font-color));
  }
}

%unstyledFieldset {
  min-width: 0; // Fix for layout bug
  border-width: 0;
  padding: 0;
  margin: 0;
}

//
// React Select => Universal Design System
//

%universalSelectControl {
  height: rem-calc(50);
  border-radius: 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%universalSelectMenu {
  border-radius: 0;
  border-width: 0;
  background-color: _var(--gray-100);
}

%universalSelectOption {
  position: relative; // position bottom border-line
  padding: _var(--padding-2) _var(--padding-5) _var(--padding-3);
  line-height: _var(--padding-5);
  cursor: pointer;

  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: _var(--padding-5);
    left: _var(--padding-5);
    height: 1px;
    background-color: _var(--gray-300);
  }

  &[class*='--is-selected'] {
    background-color: _var(--primary-color);
  }
}

%universalSelectOption--icon {
  display: flex;

  > svg {
    margin-right: _var(--padding-2);
  }
}

%universalSelectOption--iconSelected {
  > svg path {
    fill: $white !important;
  }
}
