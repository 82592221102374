@import '../../../styles/index.scss';

.header {
  margin-bottom: _var(--global-xlg-padding);
}

.content {
  margin-bottom: _var(--global-xxlg-padding);
}

//
// Typography
//

.heading {
  margin-bottom: rem-calc(18);
  font-size: rem-calc(20);
}

.subHeading {
  margin-bottom: 0;
  color: $gray-60;
}
