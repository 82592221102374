@import '/src/styles/index.scss';

.container {
  background-color: $white;
  padding-inline: $global-lg-padding;

  [class*='bg-accent'] {
    background-color: $gray-10 !important;
  }
}
