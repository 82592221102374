$accordionHeaderBorderColor: _var(--gray-200);

%accordionHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: _var(--padding-4) _var(--padding-6);
  font-size: _var(--font-size-lg);
  line-height: _var(--line-height-lg);
  border-bottom: 1px solid $accordionHeaderBorderColor;
  cursor: pointer;
}

%accordionHeadingText {
  display: block;
  flex: 1 0 0;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: _var(--padding-2);
  }
}

%accordionHeadingIcon {
  flex: 0 1 auto;
  transform: rotate(0deg);
  transition: all 0.25s linear;
}
