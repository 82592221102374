@import '/src/styles/index.scss';

$heroFeaturedPurchaseBottomPadding: rem-calc(44);

.container {
  flex-grow: 1;
  background-color: $gray-100;
}

.main {
  margin: 0 auto;
  max-width: rem-calc(1370);
  padding-top: rem-calc(28);
  padding-bottom: $heroFeaturedPurchaseBottomPadding;

  &__content,
  &__aside,
  &__footer {
    margin: 0 auto;
    max-width: rem-calc(375);
  }

  &__content,
  &__footer {
    padding-left: _var(--global-padding);
    padding-right: _var(--global-padding);
  }

  // Center child image
  &__aside {
    display: flex;
    align-items: center;
    padding: 0 _var(--global-padding);
    margin: _var(--global-xxlg-padding) auto;
    position: relative; // Position video
  }
  // &__content {}
  // &__footer {}

  @include media-query(tablet) {
    & {
      display: flex;
      height: calc(100vh - 90px);
      min-height: rem-calc(730);
      position: relative; // contain absolute footer
    }

    &__content,
    &__footer {
      padding-left: _var(--global-xlg-padding);
      padding-right: _var(--global-xlg-padding);
    }

    &__content,
    &__aside {
      margin: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: rem-calc(530);
    }

    &__aside {
      flex: 1 1 auto;
      max-width: unset;
    }

    &__footer {
      position: absolute;
      bottom: $heroFeaturedPurchaseBottomPadding;
      left: 0;
    }
  }
}

.video {
  position: absolute;
  width: rem-calc(120);
  height: rem-calc(80);
  bottom: calc(-1 * #{_var(--global-padding)});
  right: calc(-1 * #{_var(--global-sm-padding)});

  @include media-query(tablet) {
    width: rem-calc(200);
    height: rem-calc(130);
    bottom: _var(--global-xxxlg-padding);
    right: _var(--global-xxlg-padding);
  }
}

.buttonList {
  margin-top: _var(--global-xxlg-padding);
}

.buttonItemLink {
  display: block;
  margin-bottom: rem-calc(12);
}

.buckets {
  display: flex;
  list-style: none;

  > li {
    display: block;
    flex-grow: 1;
  }

  > li:not(:last-child) {
    margin-right: _var(--global-padding);
  }

  @include media-query(tablet) {
    > li:not(:last-child) {
      margin-right: _var(--global-xxxlg-padding);
    }
  }
}

.bucketIconList {
  display: flex;

  > li {
    display: block;
  }

  > li:not(:last-child) {
    margin-right: _var(--global-sm-padding);
  }
}

//
// Typography
//

.heading {
  margin-bottom: rem-calc(23);
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  color: $white;

  @include media-query(desktop) {
    margin-bottom: rem-calc(34);
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

.subHeading {
  margin-bottom: 0;
  font-size: $h6-font-size;
  line-height: $h6-line-height;
  color: $gray-60;

  @include media-query(desktop) {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }
}

.minorHeading {
  display: block;
  margin-bottom: rem-calc(6);
  font-size: $small-font-size;
  line-height: $small-line-height;
  color: $gray-60;
}

.bucketIcon {
  width: rem-calc(26);
  max-height: rem-calc(26);
}

.featureText {
  color: $white;
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

.smallText {
  display: block;
  color: $white;
  font-size: $small-font-size;
  line-height: $small-line-height;
}
