$loading-gray-light: #f5f6f7;
$loading-gray-accent: #eee;

// #f5f6f7
// #eee
// #f5f6f7

%aniLoading {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(
      110deg,
      $loading-gray-light 73%,
      $loading-gray-accent 75%,
      $loading-gray-light 77%,
      $loading-gray-light 78%,
      $loading-gray-accent 84%,
      $loading-gray-accent 88%,
      $loading-gray-light 94%,
      $loading-gray-light 100%
    );
    background-size: 200% 100%;
    background-position: 0 center;
    border-radius: inherit;
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
  }
}

%aniLoadingWhite {
  @extend %aniLoading;

  &:before {
    background-image: linear-gradient(
      110deg,
      $white 73%,
      $loading-gray-accent 75%,
      $white 77%,
      $white 78%,
      $loading-gray-accent 84%,
      $loading-gray-accent 88%,
      $white 94%,
      $white 100%
    );
  }
}

@keyframes loader {
  0% {
    background-position-x: 0;
  }
  40%,
  100% {
    background-position-x: -200%;
  }
}
