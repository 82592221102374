@import '../../../../styles/index.scss';

.link {
  display: inline-flex;
  align-items: center;

  > svg {
    display: inline-block;
    margin-left: rem-calc(4);
    max-width: _var(--global-padding);
    max-height: _var(--global-padding);
  }
}
