@import 'src/styles/index.scss';

$handle-size: rem-calc(12);
$handle-position-offset: rem-calc(-5);

.container {
  position: relative;
}

.handle {
  position: absolute;
  width: $handle-size;
  height: $handle-size;
  border-radius: 50%;
  background: _var(--white);
  border: 1px solid _var(--primary-color);
  z-index: 1;

  &:hover,
  &--active {
    background: _var(--primary-color);
  }

  &--topLeft {
    top: $handle-position-offset;
    left: $handle-position-offset;
    cursor: nwse-resize;
  }

  &--topRight {
    top: $handle-position-offset;
    right: $handle-position-offset;
    cursor: nesw-resize;
  }

  &--bottomLeft {
    bottom: $handle-position-offset;
    left: $handle-position-offset;
    cursor: nesw-resize;
  }

  &--bottomRight {
    bottom: $handle-position-offset;
    right: $handle-position-offset;
    cursor: nwse-resize;
  }

  &--middleLeft {
    top: 50%;
    left: $handle-position-offset;
    transform: translateY(-50%);
    cursor: ew-resize;
  }

  &--middleRight {
    top: 50%;
    right: $handle-position-offset;
    transform: translateY(-50%);
    cursor: ew-resize;
  }

  &--middleTop {
    top: $handle-position-offset;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize;
  }

  &--middleBottom {
    bottom: $handle-position-offset;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize;
  }
}

.cursorTooltip {
  color: _var(--body-background-color);
  background: _var(--body-font-color);
  padding: _var(--padding-2) _var(--padding-3);
  border-radius: _var(--rounded-lg);
  font-family: _var(--body-font-family);
  font-size: _var(--small-font-size);
  line-height: _var(--small-line-height);

  position: absolute;
  cursor: default;
  user-select: none;
}
