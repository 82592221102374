@import '../../../../../../styles/index.scss';

.container {
  margin-bottom: _var(--global-padding);

  &--active {
    @extend %noticeIcon;
  }
}

.heading {
  @extend %togglePanel;
  padding: _var(--global-sm-padding) _var(--global-md-padding);
  background-color: lighten($gray-10, 2.2);
  border: 1px solid $gray-30;
  border-radius: $global-radius;
  cursor: pointer;

  .container--open > & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__text {
    @extend %togglePanel__text;
    font-size: $font-size;
    color: $gray-70;
  }

  // NOTE: rotating chevron icon available
  //       via %togglePanel object
  &__button {
    display: flex;
    color: $gray-50;
    font-size: rem-calc(13);
    line-height: rem-calc(22);

    > svg {
      max-width: rem-calc(19);
      margin-left: _var(--global-xsm-padding);
      transform: translateY(-1px);

      > * {
        fill: $gray-50;
      }
    }
  }
}

.content {
  display: none;
  padding: _var(--global-padding) _var(--global-md-padding);
  border: 1px solid $gray-30;
  border-top-width: 0;
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;
}

.container--open > .content {
  display: block;
}
