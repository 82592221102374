@import '../../../../styles/index.scss';

.container {
  list-style: none;
}

.item {
  display: block;
  padding: rem-calc(9) 0 rem-calc(11);
  color: $gray-60;
  border-bottom: 1px solid $gray-20;

  &:last-child {
    border-bottom-width: 0;
  }

  &.isChecked {
    color: $gray-100;
  }
}
