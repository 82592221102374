@import '../../../styles/index.scss';

$mobileFooterHeight: rem-calc(84);

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: _var(--body-background-color);
}

.main,
.sidebar {
  overflow: auto;
}

//
// Mobile layout
//

// Header
.sidebar {
  flex: 1 1 auto;
  border-bottom: 1px solid _var(--gray-200);

  > * {
    padding-left: _var(--padding-5);
    padding-right: _var(--padding-5);
  }

  &__header {
    max-width: rem-calc(450);
    padding-top: _var(--padding-5);
  }

  &__main {
    max-width: rem-calc(410);
    padding-top: _var(--padding-10);
    padding-bottom: _var(--padding-5);
  }
}

// Middle / Primary
.main {
  flex: 1 0 67%;
  background-color: _var(--gray-100);
}

// Bottom
.footer {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;
  min-height: $mobileFooterHeight;
  padding: _var(--padding-5);
  background-color: _var(--body-background-color);
  border-top: 1px solid _var(--gray-200);
}

//
// Tablet/Desktop layout
//

.container--twoCols {
  display: flex;
  flex-direction: row-reverse;

  .main,
  .sidebar {
    height: 100%;
  }

  .main {
    width: 66.666667%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .sidebar {
    width: 33.333333%;
    max-width: rem-calc(390);
    border-bottom-width: 0;
    border-left: 1px solid _var(--gray-300);

    display: flex;
    flex-direction: column;

    > * {
      padding-left: _var(--padding-10);
      padding-right: _var(--padding-10);
    }

    &__header {
      padding-top: _var(--padding-17_5);
    }

    &__main {
      flex-grow: 2;
    }
  }

  .footer {
    height: auto;
    position: relative;
    padding-top: _var(--padding-10);
    padding-bottom: _var(--padding-10);
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
}
