@import 'src/styles/index.scss';

.container {
  &--active {
    @extend %noticeIcon;
  }

  &--pill {
    &:not(:last-child) {
      margin-bottom: _var(--padding-5);
    }
  }

  &--compact {
    width: 100%;

    // Note: This is a temporary fix for the compact container
    &.container--active::after {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: _var(--padding-1);
  width: 100%;

  &:not(&--noPadding) {
    padding: _var(--padding-3);
  }

  &--isMobile {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.heading {
  width: 100%;
  align-items: center;
  cursor: pointer;
  @extend %togglePanel;

  &__icon {
    margin-right: _var(--padding-2);
    display: flex;
    align-items: center;
    width: rem-calc(24);
    height: rem-calc(24);
    flex-shrink: 0;

    svg,
    height {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    font-size: _var(--body-font-size);
    color: _var(--gray-700);
    display: block;
    text-align: left;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @extend %togglePanel__text;
  }

  &__state {
    display: flex;
    align-items: center;
  }

  &__arrow {
    width: _var(--padding-2);
    transition: all 0.3s cubic-bezier(0.85, 0, 0.15, 1);
    transition-delay: 135ms;
    overflow: hidden;

    svg path {
      fill: _var(--gray-500);
    }

    &--active {
      margin-left: _var(--padding-2);
      width: _var(--padding-10);
    }
  }

  &--pill {
    padding: _var(--padding-2) 0 _var(--padding-2) _var(--padding-3);
    background-color: lighten($gray-10, 2.2);
    border: 1px solid _var(--gray-300);
    border-radius: _var(--rounded);
  }

  &--compact {
    min-height: rem-calc(44);
    padding: _var(--padding-1) 0 _var(--padding-1) _var(--padding-4);
    border-top: 1px solid _var(--gray-100);

    .container:last-child & {
      border-bottom: 1px solid _var(--gray-100);
    }

    .heading__arrow {
      width: _var(--padding-4);

      &--active {
        width: _var(--padding-8);
      }
    }

    .heading__text {
      font-size: _var(--small-font-size);
      line-height: _var(--small-line-height);
      font-weight: _var(--font-bold);
    }

    &.heading--large {
      min-height: rem-calc(60);

      .heading__icon {
        margin-right: _var(--padding-4);
      }

      .heading__text {
        color: _var(--body-font-color);
        font-size: _var(--font-size-xl);
        font-weight: _var(--font-semibold);
        line-height: _var(--line-height-xl);
      }
    }
  }
}

.floating {
  z-index: $floatingIndex;
  background: _var(--body-background-color);
  width: 100%;
  font-family: _var(--body-font-family);
  border-radius: _var(--rounded);
  border: 1px solid _var(--gray-300);
  box-shadow: 0px 2px 10px 0px rgba(18, 19, 26, 0.15);
  max-width: rem-calc(366);
}

.floatingContent {
  display: flex;
  flex-direction: column;
  gap: _var(--padding-1);
  max-height: min(rem-calc(360), 50vh);
  overflow: auto;
}

.arrow {
  z-index: $floatingIndex;
  path {
    fill: _var(--white);
  }
}

// Elements

.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: rem-calc(21);
  min-height: rem-calc(21);
  border-radius: rem-calc(11);
  flex-shrink: 0;
  font-size: _var(--small-font-size);
  line-height: _var(--small-line-height);
  background: _var(--gray-700);
  color: _var(--white);
  padding: 0 rem-calc(6);
}

.color {
  display: block;
  width: rem-calc(21);
  height: rem-calc(21);
  border-radius: 50%;
  flex-shrink: 0;

  &--transparent {
    border: 1px dashed _var(--gray-700);
  }

  &:not(&--transparent) {
    border: 2px solid _var(--white);
    box-shadow: -1px 2px 2px 0px rgba(0, 0, 0, 0.09);
  }
}
