@import '../../../styles/index.scss';

.container {
  margin: 0 auto;

  &--grid {
    max-width: rem-calc(1080);
    display: grid;
    grid-template-columns: rem-calc(275) auto;
    align-items: start;
    grid-gap: _var(--global-xlg-padding);
  }
}

.sidebar {
  padding: rem-calc(20) _var(--global-xlg-padding) _var(--global-sm-padding);
  margin-bottom: _var(--global-xlg-padding);
  border-radius: $global-radius;
  border: 1px solid $gray-10;
}

.filters {
  margin-top: _var(--global-padding);

  &__row > *:last-child {
    display: flex;
    justify-content: space-between;
  }
}

.buttonList {
  border-width: 0;

  > li:not(:last-child) {
    margin-right: _var(--global-sm-padding);
    margin-bottom: _var(--global-sm-padding);
  }
}

.list {
  > .item {
    margin-bottom: _var(--global-padding);
  }

  > .item:last-child {
    margin-bottom: 0;
  }

  &--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: _var(--global-padding);

    > .item {
      margin-bottom: 0;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  position: relative; // for focused overlay
  background-color: $gray-10;
  border: 2px solid $gray-20;
  cursor: pointer;

  &--active {
    border-color: $blue-primary;
    cursor: default;
  }

  &__preview {
    max-width: 100%;
    padding: _var(--global-padding);

    > img {
      box-shadow: 0 1px rem-calc(8) rgba(0, 0, 0, 0.1);
    }
  }

  &__footer {
    flex: 1 1 auto;
    padding: _var(--global-md-padding) _var(--global-xlg-padding);
    background-color: $white;
  }
}

.itemOverlay {
  display: none;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: _var(--global-lg-padding) 0 0;
  overflow: auto;
  background-color: $white;

  .item--active & {
    display: flex;
  }

  > * {
    padding-left: _var(--global-xlg-padding);
    padding-right: _var(--global-xlg-padding);
  }

  &__meta {
    display: flex;

    > * {
      margin-bottom: _var(--global-sm-padding);
      line-height: $line-height;
    }

    > *:not(:last-child) {
      margin-right: _var(--global-sm-padding);
    }
  }

  &__footer {
    margin-top: auto; // align at bottom of item
    position: sticky;
    padding: _var(--global-padding) _var(--global-xlg-padding) rem-calc(16);
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: $white;
    border-top: 1px solid $gray-20;

    > *:not(:last-child) {
      margin-right: _var(--global-md-padding);
      margin-bottom: _var(--global-sm-padding);
    }
  }
}

//
// Typography
//

.itemHeading {
  margin-bottom: rem-calc(4);
  color: $gray-90;
}

.activeHeading {
  margin-bottom: _var(--global-sm-padding);
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

.itemDescription {
  color: $gray-90;
  font-size: $small-font-size;
  line-height: $small-line-height;
}
