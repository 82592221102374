@import '../../../styles/index.scss';

%layoutMainDefaults {
  height: 100%;
  flex: 0 0 100%;

  &.-topWidgetEditNav {
    height: calc(100% - $widgetNavHeight);
  }

  // Make app's primary scrolling container
  overflow: auto;
  background-color: $gray-10;
  scroll-behavior: smooth;
  scroll-padding-top: rem-calc(42);

  @include media-query(desktop) {
    flex: 1;
    height: 100%;
  }
}

.main {
  @extend %layoutMainDefaults;

  // Allow public nav to be
  // always in view
  // DEPRECATED 14/7/23
  // &--public {
  //   margin-top: auto;
  //   height: calc(100% - #{$mobilePublicNavHeight});
  // }

  // // Enable sticky behavior of
  // // public navigation
  // @include media-query(large-tablet) {
  //   &--public {
  //     height: 100%;
  //     margin-top: unset;
  //     padding-top: $publicNavHeight;
  //   }
  // }
}

.singleColumn {
  max-width: rem-calc(700);
  margin: 0 auto;
  padding: _var(--global-lg-padding);
}

.twoColumn {
  display: flex;
  flex-direction: column;
  @extend %layoutMainDefaults;

  &--constrained {
    max-width: rem-calc(550);
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  &__right {
    padding: _var(--global-xxlg-padding) _var(--global-padding);
    background-color: $white;
  }

  @include media-query(desktop) {
    flex: 1;
    height: 100%;
    flex-direction: row;

    &--constrained {
      height: auto;
      max-width: rem-calc(1100);
    }

    &__left,
    &__right {
      height: 100%;
      overflow: auto;
    }

    &__left {
      flex: 1;
      padding: _var(--global-xlg-padding);
    }

    &__right {
      flex: 0 0 40%;
      margin: 0;
      padding: _var(--global-xxxxlg-padding) _var(--global-xlg-padding)
        _var(--global-xlg-padding);
    }
  }
}

.twoColumnFlyOut {
  @extend %layoutMainDefaults;

  @include media-query(large-tablet) {
    display: flex;
    flex-wrap: nowrap;

    &__sidebar,
    &__primary {
      height: 100%;
      overflow: auto;
    }

    &__sidebar {
      flex: 1 0 61.8%;
      margin-bottom: 0;
      z-index: $tooltipZindex + 1;
    }

    // Expand right column to
    // fill the available space
    &__primary {
      flex-grow: 1;
      overflow: hidden;
    }
  }

  @include media-query(desktop) {
    // Account for primary nav
    &:not(.-topWidgetEditNav) {
      max-width: calc(100vw - $primaryNavWidth);
    }

    &__sidebar {
      max-width: rem-calc(492);
    }
  }
}
