@import '/src/styles/index.scss';
@import '../settings.scss';

$bigSingleColumn: 160px;
$twoColumnLayoutBreakpoint: 266px;

.container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  container-type: inline-size;
  container-name: layout-controls-data-button;
}

.dataButton,
.errorButton {
  align-items: center;
  font-size: $small-font-size;
  padding-inline: rem-calc(20);
  white-space: nowrap;
}

.errorIndicator,
.dataButton__errorIndicator {
  @extend %indicator;
  @extend %indicator--alert;
  width: rem-calc(20);
  height: rem-calc(20);
}

.dataButton {
  display: flex;
  position: relative;
  gap: rem-calc(12);
  color: $gray-70;
  border: 1px solid $gray-20;
  background-color: $white;
  border-bottom-width: 0;
  border-right-width: 0;

  &__errorIndicator {
    position: absolute;
    top: rem-calc(2);
    left: rem-calc(7);
    opacity: 0.85;
  }

  @container layout-controls-data-button (min-width: #{$bigSingleColumn}) {
    box-shadow: $expandedBoxShadow;

    .expandIcon {
      display: inline;
    }
  }

  @container preview-container (min-width: #{$expandedLayoutWidth}) {
    border-right-width: 1px;
    border-bottom-width: 1px;
    background-color: $gray-10;
  }

  @container layout-controls-data-button (min-width: #{$twoColumnLayoutBreakpoint}) {
    &__errorIndicator {
      display: none;
    }
  }
}

.errorButton {
  display: none;
  gap: rem-calc(8);
  color: $red-primary;
  background-color: #fff9f9;
  border: 1px solid #e9a7a7;
  box-shadow: 0 0 14px rgba(#ff4e4e, 22%);

  @container layout-controls-data-button (min-width: #{$twoColumnLayoutBreakpoint}) {
    display: flex;

    & + .dataButton {
      border-left-width: 0;
    }
  }
}

.sheetIcon {
  width: rem-calc(18);
  height: rem-calc(24);
}

.expandIcon {
  display: none;
}
