@use 'sass:math';
// Keep in sync with `@/config/design`

// Base
$rem-base: 16px;
$radius-base: 4px;
$global-padding: 1.25rem; // 20px;
$base-line-height: 1;
$product-namespace: exk;

// Application Colors
$gray-100: #12131a; // Body font color
$gray-90: #252833;
$gray-80: #3a3d4d;
$gray-70: #515466;
$gray-60: #696c80;
$gray-50: #828699;
$gray-40: #9a9eb2;
$gray-30: #babdcc;
$gray-20: #d8dae5;
$gray-10: #ebecf2;
$gray-5: #f5f5f7;
$white: #fff;
$blue-primary: #004de5;
$blue-hover: #0044cc;
$blue-active: #003cb2;
$blue-info: #6ea9db;
$blue-light: #dee9fb;
$blue-accent: #3881f3;
$green-primary: #179942;
$green-secondary: #cef2da;
$green-teirtary: #74d691;
$red-primary: #ff4e4e;
$red-secondary: #ffd9d9;
$warning-color: #fdd03a;
$warning-primary: #fdd039;
$warning-secondary: #fcecaa;
$purple-color: #7a61fa;

// Radius
$global-radius: rem-calc(4);
$global-lg-radius: rem-calc(8);
$global-xlg-radius: rem-calc(16);
$global-rounded: 1000px;

// Padding
// (Sync w/ ./root-variables.scss)
$global-xsm-padding: math.div($global-padding, 4); // 5px
$global-sm-padding: math.div($global-padding, 2); // 10px
$global-md-padding: ($global-padding * 0.75); // 15px
// 20px = $global-padding
$global-lg-padding: ($global-padding * 1.25); // 25px
$global-xlg-padding: ($global-padding * 1.5); // 30px
$global-xxlg-padding: ($global-padding * 2); // 40px
$global-xxxlg-padding: ($global-padding * 2.5); // 50px
$global-xxxxlg-padding: ($global-padding * 3); // 60px
$global-xxxxxlg-padding: ($global-padding * 3.5); // 70px

// Modal
$modal-zindex: 1000;

// Layout Magic Numbers
$mobile-max-width: inline-rem-calc(599);

// Tablet Min Width
$tablet-breakpoint: inline-rem-calc(600);

// Large Tablet Min Width
$tablet-large-breakpoint: inline-rem-calc(740);

// Desktop Min Width
$desktop-breakpoint: inline-rem-calc(990);

// Large Desktop Min Width
$large-desktop-breakpoint: inline-rem-calc(1200);

/// Layout Breakpoints
/// @type Map<Number>
$breakpoints: (
  'tablet': $tablet-breakpoint,
  'large-tablet': $tablet-large-breakpoint,
  'desktop': $desktop-breakpoint,
  'large-desktop': $large-desktop-breakpoint,
);
