@import '../../../../styles/index.scss';

.container {
  // Center content
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // Center self
  margin: 0 auto;
  padding: 0 _var(--global-padding);

  container-name: section-header;
  container-type: inline-size;

  > * {
    text-align: center;
    max-width: rem-calc(656);
  }
}

.heading {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  margin-bottom: _var(--global-xxlg-padding);

  @container section-header (min-width: #{$tablet-breakpoint}) {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
  }

  @container section-header (min-width: #{$tablet-large-breakpoint}) {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    margin-bottom: _var(--global-xxxlg-padding);
  }
}

.subscript {
  display: inline-flex;
  align-items: center;
  margin-bottom: _var(--global-padding);
  font-size: $font-size;
  line-height: $line-height;
  color: $gray-70;

  > svg {
    display: inline-block;
    width: rem-calc(16);
    max-height: _var(--global-padding);
    margin-right: _var(--global-sm-padding);

    path {
      fill: $gray-70;
    }
  }
}
