%noticeIcon {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: rem-calc(-4);
    right: rem-calc(-4);
    display: block;
    width: rem-calc(13);
    height: rem-calc(13);
    background-color: $blue-primary;
    border: 3px solid $white;
    outline: 1px solid $gray-40;
    border-radius: 100%;
    box-shadow: -2px 2px 5px rgba($gray-100, 0.14);
  }
}
