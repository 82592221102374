%aniFadeInOut {
  animation: fadeInOut 1s linear;
  animation-iteration-count: infinite;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
