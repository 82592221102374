@import '../../../styles/index.scss';

//
// Blocks
//
.overlay {
  @extend %modalOverlay;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &--high {
    z-index: $drawerIndex + 1;
  }
}

.container {
  @extend %modal;
  position: relative; // position close button

  &--cover {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: $primaryNavFlyOutIndex;

    // @include media-query(desktop) {
    //   height: calc(100vh - $widgetNavHeight);
    // }
    //
    //   // @include media-query(large-tablet) {
    //     height: 100%;
    //     min-height: 100%;
    //     position: sticky; // 1 column cover logic
    // }
  }

  &--medium {
    max-width: rem-calc(940);
    width: calc(100% - (_var(--global-xlg-padding) * 2));
    height: calc(100% - (_var(--global-xlg-padding) * 2));

    @include media-query(desktop) {
      width: calc(100% - (_var(--global-xxlg-padding) * 2));
      height: auto;
    }
  }

  &--big {
    max-width: unset;
    max-height: unset;
    width: calc(100% - (_var(--global-xlg-padding) * 2));
    height: calc(100% - (_var(--global-xlg-padding) * 2));

    @include media-query(desktop) {
      width: calc(100% - (_var(--global-xxlg-padding) * 2));
      height: calc(100% - (_var(--global-xxlg-padding) * 2));
    }
  }

  &__closeButton {
    position: absolute;
    padding: 0;
    color: $gray-30;
    top: _var(--global-padding);
    right: _var(--global-padding);
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    z-index: 10;

    &:focus {
      border-width: 0;
      outline: none;
    }
  }

  @include media-query(tablet) {
    &__closeButton {
      top: _var(--global-xxlg-padding);
      right: _var(--global-xxlg-padding);
    }
  }
}

.main {
  padding: _var(--global-xxlg-padding) _var(--global-lg-padding);

  @include media-query(tablet) {
    padding: rem-calc(116) rem-calc(120);
  }
}

//
// Indexable Util Classes
//
.-indexable {
  display: none;
}

.-indexable.-show {
  &.overlay {
    display: flex;
  }

  &:not(.overlay) {
    display: block;
  }
}
