@import 'src/styles/index.scss';

.cursorTooltip {
  color: _var(--body-background-color);
  background: _var(--body-font-color);
  padding: _var(--padding-2) _var(--padding-3);
  border-radius: _var(--rounded-lg);
  font-family: _var(--body-font-family);
  font-size: _var(--small-font-size);
  line-height: _var(--small-line-height);

  position: absolute;
  cursor: default;
  user-select: none;
}
