@import '../../../styles/index.scss';
@import './settings.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  container-type: inline-size;
  container-name: preview-container;
  background-color: _var(--gray-200);
  overflow: hidden;

  // Add overlay shadow
  // to make form appear hovering
  // over the preview container
  box-shadow: inset rem-calc(-15) 0 rem-calc(38) rgba(0, 0, 0, 0.2);

  .scrollBoundary {
    flex-grow: 1;

    &--disabled {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:not(&--disabled) {
      overflow: auto;
      padding: _var(--padding-4) 0;
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: max(_var(--widget-preview-width), 120%);
    min-height: max(_var(--widget-preview-height), 120%);

    &--collapsed {
      height: inherit;
    }

    // Mobile preview (default)
    &__canvas {
      overflow: auto;

      // Align widget to the
      // left of the preview element
      // Defaults to centered
      > section {
        margin-left: 0;
      }

      &:hover,
      &--focus {
        border-color: _var(--primary-color);
      }

      &--hasBorders {
        border: 1px solid _var(--gray-300);
      }
    }
  }

  // Add whitespace to accomodate
  // floating layout controls below
  @container preview-container (min-width: 740px) {
    .scrollBoundary {
      /* padding-bottom: rem-calc(40); */
    }
  }

  @container preview-container (max-width: #{inline-rem-calc(599)}) {
    .scrollBoundary {
      &:not(&--disabled) {
        margin-bottom: $mobileToolbarHeight;
      }
    }
  }
}
