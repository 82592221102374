@import 'src/styles/index.scss';

.menuList {
  display: flex;
  align-items: center;

  &--horizontal {
    gap: _var(--padding-2);
  }

  &--vertical {
    gap: _var(--padding-4);
    flex-direction: column;
  }
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: _var(--padding-1);
  position: relative; // Contain indicator

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: _var(--padding-8);
    height: _var(--padding-8);
    border-radius: _var(--rounded);
    border: 1px solid _var(--body-background-color);
  }

  &__title {
    font-size: _var(--xxsmall-font-size);
    font-weight: _var(--font-bold);
    line-height: _var(--xxsmall-line-height);
    color: _var(--gray-500);
  }

  &__indicator {
    @extend %activeIndicator;
    top: 0;
    right: 0;
    width: rem-calc(12);
    height: rem-calc(12);
    border-width: 0;
  }

  &:not(&--hasError):hover &__indicator {
    opacity: 0;
  }

  &--active,
  &:hover {
    & .menuItem__icon {
      border-color: _var(--gray-100);
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    }
    & .menuItem__title {
      color: _var(--gray-700);
    }
  }

  &--hasError &__indicator {
    background: _var(--error-primary-color);
  }

  .menuList--horizontal & {
    min-width: rem-calc(44);
  }
}
