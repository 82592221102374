@import 'src/styles/index.scss';

.container {
}

.menu {
  z-index: $floatingIndex;
  background: _var(--body-background-color);
  padding: _var(--padding-1);
  border-radius: _var(--rounded-lg);
  border: 1px solid _var(--border-color-muted);
  box-shadow: 0px 6px 12px 0px #0000001a;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: _var(--padding-2);
  width: 100%;
  min-height: rem-calc(40);
  border-radius: _var(--rounded);
  padding: _var(--padding-2) _var(--padding-2_5);
  transition: background 250ms;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: _var(--secondary-color);
  }

  &__icon {
    width: rem-calc(22);
    height: rem-calc(22);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-family: _var(--body-font-family);
    font-size: _var(--body-font-size);
    line-height: _var(--body-line-height);
  }
}
