%indicator {
  position: relative;

  &::after {
    content: '';
    width: rem-calc(8);
    height: rem-calc(8);
    position: absolute;
    top: rem-calc(6);
    left: rem-calc(6);
    display: block;
    border-radius: 100%;
  }
}

%indicator--warning {
  &::after {
    background-color: $warning-primary;
    outline: 6px solid $warning-secondary;
  }
}

%indicator--alert {
  &::after {
    background-color: $red-primary;
    outline: 6px solid $red-secondary;
  }
}
